<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-md-12"></div>
    </div>

    <div class="row">
      <DataTable
        :value="customers"
        :lazy="true"
        :paginator="true"
        class="p-datatable-customers"
        :rows="per_page"
        :totalRecords="totalRows"
        @page="onPage($event)"
        @sort="onSort($event)"
        dataKey="id"
        :rowHover="true"
        :filters="filters"
        :loading="loading"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="[10, 20, 30]"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
      >
        <template #header>
          <div class="table-header">
            List of Users

            <router-link to="/users/add">
              <button class="btn btn-primary">Add user</button>
            </router-link>
          </div>
        </template>

        <template #empty> No record found. </template>
        <template #loading> Loading data. Please wait. </template>
        <!-- <Column selectionMode="multiple" headerStyle="width: 3em"></Column> -->
        <Column
          header="User Name"
          :sortable="true"
          sortField="user_name"
          filterField="user_name"
          filterMatchMode="contains"
        >
          <template #body="slotProps">
            <span class="p-column-title">User Name</span>

            <router-link
              :to="'/users/list/' + slotProps.data.id"
              class="text-dark"
              >{{ slotProps.data.user_name }}
            </router-link>
          </template>
          <template #filter>
            <InputText
              type="text"
              v-model="filters.user_name"
              @keydown.enter="topFilter(filters.user_name, 'user_name')"
              class="p-column-filter"
              placeholder="Search by username"
            />
          </template>
        </Column>
        <Column
          header="Email"
          :sortable="true"
          sortField="email"
          filterField="email"
          filterMatchMode="contains"
        >
          <template #body="slotProps">
            <span class="p-column-title">Email</span>

            {{ slotProps.data.email }}
          </template>
          <template #filter>
            <InputText
              type="text"
              v-model="filters.email"
              @keydown.enter="topFilter(filters.email, 'email')"
              class="p-column-filter"
              placeholder="Search by email"
            />
          </template>
        </Column>
        <Column
          header="Old Email"
          :sortable="true"
          sortField="old_email"
          filterField="old_email"
          filterMatchMode="contains"
        >
          <template #body="slotProps">
            <span class="p-column-title text-center">Old Email</span>

            {{ slotProps.data.old_email || " -- " }}
          </template>
          <template #filter>
            <InputText
              type="text"
              v-model="filters.old_email"
              @keydown.enter="topFilter(filters.old_email, 'old_email')"
              class="p-column-filter"
              placeholder="Search by old email"
            />
          </template>
        </Column>
        <!-- <Column header="Mobile No" :sortable="true" sortField="mobile" filterField="mobile" filterMatchMode="contains">
                <template #body="slotProps">
                    <span class="p-column-title">Mobile No</span>

                    {{ slotProps.data.mobile }}
                </template>
                <template #filter>
                    <InputText type="text" v-model="filters.mobile" @keydown.enter="topFilter(filters.mobile, 'mobile')" class="p-column-filter" placeholder="Search by mobile" />
                </template>
            </Column> -->

        <Column
          header="Date"
          :sortable="true"
          sortField="created_at"
          filterField="created_at"
          filterMatchMode="contains"
        >
          <template #body="slotProps">
            <span class="p-column-title">Created At</span>
            <span
              :class="'customer-badge status-' + slotProps.data.created_at"
              >{{
                new Date(slotProps.data.created_at).toLocaleDateString()
              }}</span
            >
          </template>
          <template #filter>
            <Calendar
              v-model="filters.date"
              dateFormat="dd-mm-yy"
              :showButtonBar="true"
              class="p-column-filter"
              @date-select="topFilter(filters.date, 'date')"
              placeholder="Created At"
            />
          </template>
        </Column>

        <Column
          field="user_verify"
          header="Status"
          :sortable="true"
          filterMatchMode="equals"
        >
          <template #body="slotProps">
            <span class="p-column-title">Status</span>
            <span
              :class="'customer-badge status-' + slotProps.data.user_verify"
              >{{
                slotProps.data.user_verify == 1 ? "verified" : "unverified"
              }}</span
            >
          </template>
          <template #filter>
            <Dropdown
              v-model="filters.status"
              @change="topFilter($event.value, 'status')"
              :options="statuses"
              placeholder="Select a Status"
              class="p-column-filter"
              :showClear="true"
            >
              <template #option="slotProps">
                <span :class="'customer-badge status-' + slotProps.option">{{
                  slotProps.option
                }}</span>
              </template>
            </Dropdown>
          </template>
        </Column>

        <Column
          field="user_kyc_status"
          header="Kyc Status"
          :sortable="true"
          filterMatchMode="equals"
        >
          <template #body="slotProps">
            <span class="p-column-title">Kyc Status”</span>
            {{
              slotProps.data.user_kyc_status == "new" ||
              slotProps.data.user_kyc_status == "pending"
                ? "Not Verified"
                : "Verified"
            }}
          </template>

          <template #filter>
            <Dropdown
              v-model="filters.status"
              @change="topFilter($event.value, 'user_kyc_status')"
              :options="statuses"
              placeholder="Select a Status"
              class="p-column-filter"
              :showClear="true"
            >
              <template #option="slotProps">
                <span :class="'customer-badge status-' + slotProps.option">{{
                  slotProps.option
                }}</span>
              </template>
            </Dropdown>
          </template>
        </Column>

        <Column
          header="Action"
          :sortable="true"
          sortField="status"
          filterMatchMode="equals"
        >
          <template #body="slotProps">
            <span class="p-column-title">Action</span>
            <span class="form-switch fs-5" for="withdrawls">
              <input
                class="form-check-input"
                v-model="slotProps.data.status"
                id="withdrawls"
                type="checkbox"
                role="switch"
                @change="showSwalf(slotProps.data.id, slotProps.data.status)"
            /></span>
          </template>

          <template #filter>
            <Dropdown
              v-model="filters.enable"
              @change="topFilter($event.value, 'enable')"
              :options="actionss"
              placeholder="Select a Action"
              class="p-column-filter"
              :showClear="true"
            >
              <template #option="slotProps">
                <span :class="'customer-badge status-' + slotProps.option">{{
                  slotProps.option
                }}</span>
              </template>
            </Dropdown>
          </template>
        </Column>
        <Column
          header="Fund"
          sortField="status"
        >
          <template #body="slotProps">
            <span class="form-switch fs-5" for="withdrawls">
              <Button
                v-b-modal.modal-center
                variant="white"
                @click="currentRowData = slotProps.data"
                type="button"
                icon="pi pi-cog"
                class="btn btn-primary btn-sm w-xs"
                >Fund Wallet</Button
              >
            </span>
          </template>
              </Column>
        <Column
          header="Update Email"
          sortField="status"
        >
          <template #body="slotProps">
            <span class="form-switch fs-5" for="withdrawls">
              <Button
                v-b-modal.modal-center1
                variant="white"
                @click="currentRowData = slotProps.data"
                type="button"
                icon="pi pi-cog"
                class="btn btn-primary btn-sm w-xs"
                >Update Email</Button
              >
            </span>
          </template>
        </Column>

          <!-- <template #filter>
            <Dropdown
              v-model="filters.enable"
              @change="topFilter($event.value, 'enable')"
              :options="actionss"
              placeholder="Select a Action"
              class="p-column-filter"
              :showClear="true"
            >
              <template #option="slotProps">
                <span :class="'customer-badge status-' + slotProps.option">{{
                  slotProps.option
                }}</span>
              </template>
            </Dropdown>
          </template> -->
    

        <!-- <Column
          field=""
          header="ADD crypto"
          :sortable="false"
        >
         <template  #option="slotProps" >
          <span class="p-column-title">Kyc Status”</span>
                <span class="form-switch fs-5" for="withdrawls">
              <input
                class="form-check-input"
                v-model="slotProps.data.status"
                id="withdrawls"
                type="checkbox"
                role="switch"
                @change="showSwalf(slotProps.data.id, slotProps.data.status)"
            /></span>
         
           <button type="submit" class="btn col-md-2 btn-primary">
                Submit
              </button>
          </template>
        </Column> -->
      </DataTable>
    </div>
    <b-modal
      id="modal-center"
      size="xl"
      centered
      title="CREDIT / DEBIT funds"
      title-class="font-18"
      hide-footer
      v-if="currentRowData"
    >
      <b-form class="p-2" @submit.prevent="onSubmit($event, currentRowData.id)" >
        <!-- Identity Module -->
        <b-row align-v="center">
          <h5 class="mb-4">
            Here you can add or remove crypto to user portfolio
          </h5>
        </b-row>

        <b-form-group
          class="mb-4"
          id="input-group-1"
          label="Select Currency :"
          label-for="input-1"
        >
          <b-form-select
            class="w-100 v_select"
            v-model="form.selected_currency"
            :options="currency_option"
          ></b-form-select>
          <div
            class="error"
            v-if="submitted && !$v.form.selected_currency.required"
          >
            Currency is required
          </div>
        </b-form-group>

        <b-form-group
          class="mb-4"
          id="input-group-2"
          label="Select Action :"
          label-for="input-2"
        >
          <b-form-select
            class="w-100 v_select"
            v-model="form.selected_action"
            :options="action_option"
          ></b-form-select>
          <div class="error" v-if="submitted && !$v.form.selected_action.required" >
            Action is required
          </div>
        </b-form-group>

        <b-form-group
          class="mb-3"
          id="input-group-3"
          label="Amount"
          label-for="input-3"
        >
          <b-form-input
            id="input-1"
            v-model="form.balance"
            type="text"
            placeholder="Enter amount"
            class=""
          ></b-form-input> 
          <div class="error" v-if="submitted && !$v.form.balance.required" >
            Balance is required
          </div>
          
        </b-form-group>

        <!---------------Address Proof----->

        <b-row align-v="center">
          <b-col md="12" class="text-center">
            <b-button variant="success" class="succ_btn" type="submit"
              >Save</b-button
            >
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
    <b-modal
      id="modal-center1"
      size="xl"
      centered
      title=" Change User Email Address"
      title-class="font-18"
      hide-footer
      v-if="currentRowData"
    >
      <b-form class="p-2" @submit.prevent="UpdateEmail(currentRowData.id)" >
        <!-- Identity Module -->
            

        <b-form-group
          class="mb-3"
          id="input-group-3"
          label="Enter New Email Address"
          label-for="input-3"
        >
          <b-form-input
            id="input-1"
            v-model="Uform.email"
            type="text"
            :placeholder="currentRowData.email"
            class=""
          ></b-form-input> 
          <div class="error" v-if="Usubmitted && !$v.Uform.email.required" >
            Email Adress Is required
          </div>
          
        </b-form-group>

        <!---------------Address Proof----->

        <b-row align-v="center">
          <b-col md="12" class="text-center">
            <b-button variant="success" class="succ_btn" type="submit"
              >Save</b-button
            >
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
  </Layout>
</template>

<script>
// import Multiselect from "vue-multiselect";
// import Swal from "sweetalert2";
import Layout from "../../layouts/main";
import ApiClass from "../../api/api";

import Swal from "sweetalert2";

import PageHeader from "@/components/page-header";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
// import ColumnGroup from 'primevue/columngroup';
import InputText from "primevue/inputtext";
// import MultiSelect from 'primevue/multiselect';
import Dropdown from "primevue/dropdown";

import { required } from "vuelidate/lib/validators";

import Calendar from "primevue/calendar";
// import ProgressBar from 'primevue/progressbar';

export default {
  components: {
    Layout,
    PageHeader,
    DataTable,
    Dropdown,
    Calendar,
    Column,
    InputText,
    // Multiselect,
  },
  data() {
    return {
      form: {
        selected_action: "add",
        selected_currency: "",
        balance: "",
      },
      Uform:{
        email : ''
      },
      Usubmitted:false,
      submitted:false,
      currency_option: [
        { value: null, text: "Select Option" },
        // { value: "a", text: "This is First option" },
      ],
      action_option: [
        { value: "add", text: "Add" },
        { value: "deduct", text: "Deduct" },
      ],
      slctUsers: [],
      slctCurrency: [],
      slctAmountType: [],
      totalRows: 1,
      per_page: 10,
      page: 1,
      title: "User",
      disable_status: null,
      currentRowData: null,
      items: [
        {
          text: "Dashboards",
          href: "#",
        },
        {
          text: "User",
          active: true,
        },
      ],
      filters: {
        name: null,
        email: null,
        // mobile: null,
        date: null,
        status: null,
        duration: null,
      },
      filter: "",
      customers: [],
      selectedCustomers: null,
      loading: true,
      statuses: ["verified", "unverified"],
      actionss: ["enabled", "disabled"],
      duration: ["day", "week", "month"],
    };
  },

  mounted() {
    this.client();
    this.getData();
  },

  methods: {
    showSwalf(id, status) {
      // alert('id= '+ id + 'disable_status='+ this.disable_status +'status='+status)
      this.disable_status = status ? "Enable" : "Disable";
      this.$swal({
        title: "Please Confirm.",
        text: "Are you sure you want to " + this.disable_status + " action?",
        icon: "warning",
        iconColor: "#ff0000",
        showCancelButton: true,
        cancelButtonColor: "#ff0000",
        cancelButtonText: "No",
        confirmButtonColor: "#008000",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          this.apiHit(id, status);
        } else {
          this.client();
        }
      });
    },
    async apiHit(id, status) {
      var res = await ApiClass.updateRequest(
        "admin/users/update_status/" + id + "/" + status,
        true
      );
      // console.log('resdddd=', res);

      if (res.data.status_code == 1) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: res.data.message,
          showConfirmButton: false,
          timer: 1000,
        });
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          title: res.data.message,
          showConfirmButton: false,
          timer: 1000,
        });
      }
    },
    async client() {
      await ApiClass.getRequest(
        "admin/users/get?per_page=" +
          this.per_page +
          "&page=" +
          this.page +
          "&" +
          this.filter
      ).then((res) => {
        // console.log("result=", res);
        if (res.data.status_code == 1) {
          this.loading = false;
          this.customers = res.data.data.data;
          // console.log("rahul" + this.customers);
          this.currentPage = res.data.data.current_page;
          this.totalRows = res.data.data.total;
          // console.log("users/get" , this.customers );
        }
      });
    },

    async onSubmit(event , id) {
      this.submitted = true;
      console.log({event , id , form :this.form ,invalid :this.$v})
      if (this.$v.form.$invalid) {
        return;
      }


      // return;
      this.submitted = false;

      var result = await ApiClass.postRequest("admin/fund/create", true, {
        user_id: id,
        currency: this.form.selected_currency,
        amount_type: this.form.selected_action,
        balance: this.form.balance,
      });
      if (result.data.status_code == 1) {
        // this.reset();
        Swal.fire({
          position: "center",
          icon: "success",
          title: result.data.message,
          showConfirmButton: false,
          timer: 1000,
        });
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          title: result.data.message,
          showConfirmButton: false,
          timer: 1000,
        });
      }

      this.$bvModal.hide("modal-center")

// end here
      // if(result.data.status_code == 1){
      //     this.$router.push({ name: 'User List' })
      //  }
    },

    async UpdateEmail(id){
       this.Usubmitted =  true;
      
      if (this.$v.Uform.$invalid) {
        return;
      }

      
      let result =  await ApiClass.updateRequest(`admin/users/update/${id}` , true , { email : this.Uform.email});
       if (result.data.status_code == 1) {
        // this.reset();
        Swal.fire({
          position: "center",
          icon: "success",
          title: result.data.message,
          showConfirmButton: false,
          timer: 1000,
        });
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          title: result.data.message,
          showConfirmButton: false,
          timer: 1000,
        });
      }  

      this.$bvModal.hide("modal-center1")
      this.client();





    },


    reset() {
      this.form = {
        selected_action: "",
        selected_currency: "",
        balance: "",
      }; // Empty Fields
    },
    async getData() {
      var result = await ApiClass.getRequest("admin/fund/get_fund", true);
      if (result.data.status_code == 1) {
        let curr = result.data.data.currencyList;
        this.currency_option = curr.map((el) => {
          return { value: el.symbol, text: el.symbol };
        });
      }
    },

    onPage(event) {
      if (this.page != event.page + 1) {
        this.loading = !this.loading;
        this.page = event.page + 1;
      }
      if (this.per_page != event.rows) {
        this.loading = !this.loading;
        this.per_page = event.rows;
      }
      this.client();
    },

    onSort(e) {
      this.loading = !this.loading;
      var sortby = e.sortOrder == 1 ? "asc" : "desc";
      this.filter = "sortby=" + sortby + "&sortbyname=" + e.sortField;
      this.client();
    },

    topFilter(columnVal, columnName) {
      //  if(columnName == 'status'){

      //          columnVal = columnVal == 'verified' ? true : false;
      //      }
      if (columnName == "date") {
        columnVal = columnVal.toLocaleDateString("es-US").split("T")[0];
        columnVal = columnVal.replaceAll("/", "-");

        columnVal = columnVal.split("-").reverse().join("-");
      }
      this.loading = !this.loading;

      this.$set(this.filters, columnName, columnVal);

      var x = this.filters;
      const resulted = JSON.parse(JSON.stringify(x), (key, value) =>
        value === null || value === "" ? undefined : value
      );

      let query = new URLSearchParams(resulted).toString();

      this.filter = query;

      this.client();
    },
  },
  validations: {
    form: {
      selected_currency: { required },
      selected_action: { required },
      balance: { required },
    },
    Uform:{
      email:{required}
    }
  },
};
</script>

<style lang="scss">
.v_select {
  border: 1px solid #ced4da;
  padding: 0.47rem 0.75rem;
  font-size: 0.8125rem;
  border-radius: 0.25rem;
}
.p-paginator {
  .p-paginator-current {
    margin-left: auto;
  }
}

.p-progressbar {
  height: 0.5rem;
  background-color: #d8dadc;

  .p-progressbar-value {
    background-color: #00acad;
  }
}

.table-header {
  display: flex;
  justify-content: space-between;
}

.p-datepicker {
  min-width: 25rem;

  td {
    font-weight: 400;
  }
}

.p-datatable.p-datatable-customers {
  .p-datatable-header {
    padding: 1rem;
    text-align: left;
    font-size: 1.5rem;
  }

  .p-paginator {
    padding: 1rem;
  }

  .p-datatable-thead > tr > th {
    text-align: left;
  }

  .p-datatable-tbody > tr > td {
    cursor: auto;
  }

  .p-dropdown-label:not(.p-placeholder) {
    text-transform: uppercase;
  }
}

.p-calendar .p-datepicker {
  width: 350px;
}

/* Responsive */
.p-datatable-customers .p-datatable-tbody > tr > td .p-column-title {
  display: none;
}

@media screen and (max-width: 64em) {
  .p-datatable {
    &.p-datatable-customers {
      .p-datatable-thead > tr > th,
      .p-datatable-tfoot > tr > td {
        display: none !important;
      }

      .p-datatable-tbody > tr > td {
        text-align: left;
        display: block;
        border: 0 none !important;
        width: 100% !important;
        float: left;
        clear: left;
        border: 0 none;

        .p-column-title {
          padding: 0.4rem;
          min-width: 30%;
          display: inline-block;
          margin: -0.4rem 1rem -0.4rem -0.4rem;
          font-weight: bold;
        }
      }
    }
  }
}
</style>
